import { RECEIVE_CURRENT_USER } from '../actions/currentUser';

function currentUser(state = {}, action) {
  switch (action.type) {
    case RECEIVE_CURRENT_USER:
      return action.currentUser;
    default:
      return state;
  }
}

export default currentUser;
