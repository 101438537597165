import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class AnalysisDelete extends React.Component {
  render() {
    const { currentUser, position } = this.props;

    if (!position || position.id === null || !currentUser) {
      return <div />;
    }

    return (
      <div>
        <a href={'/analysis/' + position.id} className="btn btn-xs btn-danger" data-method="delete">
          Delete
        </a>
      </div>
    );
  }
}

AnalysisDelete.propTypes = {
  position: PropTypes.shape({
    id: PropTypes.string,
  }),
  currentUser: PropTypes.shape({}),
};

AnalysisDelete.defaultProps = {
  position: null,
  currentUser: null,
};

function mapStateToProps(state) {
  const { currentUser, position } = state;

  return { currentUser, position };
}

export default connect(mapStateToProps)(AnalysisDelete);
