import { Chess } from 'chess.js';
// import { Chessground } from 'chessground';

export const CHESSJS = new Chess();
window.CHESSJS = CHESSJS; // needs to be global for rspec tests

export const CHESSGROUND_CONFIG = {
  animation: {
    duration: 500,
  },
  fen: '8/8/8/8/8/8/8/8',
  draggable: {
    showGhost: true,
  },
};

//  events: {
//    change: function(orig, dest, metadata) {
//      console.log('change');
//    },
//    move: function(orig, dest, metadata) {
//      console.log('move');
//    },
//    dropNewPiece: function(orig, dest, metadata) {
//      console.log('dropNewPiece');
//    },
//    select: function(orig, dest, metadata) {
//      console.log('select');
//    },
//    insert: function(orig, dest, metadata) {
//      console.log('insert');
//    },
//  },
