import { EDIT_NAME, CHANGE_NAME_VALUE } from '../actions/nameInput';

const initialState = {
  edit: false,
  newValue: null,
};

export function nameInputReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_NAME:
      return {
        ...state,
        edit: !state.edit,
      };
    case CHANGE_NAME_VALUE:
      return {
        ...state,
        newValue: action.newValue,
      };
    default:
      return state;
  }
}
