import { SQUARES } from 'chess.js'

export function toDests(moves) {
  const dests = new Map();

  SQUARES.forEach((s) => {
    const ms = moves({ square: s, verbose: true });
    if (ms.length) dests.set(s, ms.map((m) => m.to));
  });

  return dests;
}

export function toColor(chess) {
  return (chess.turn() === 'w') ? 'white' : 'black';
}

export function isPromotion(chess) {
  return chess.history({ verbose: true }).slice(-1)[0].promotion !== undefined;
}

export function chessGameOver(chess) {
  return chess.game_over();
}

export function sanToMove(san, chess) {
  const move = chess.move(san);
  chess.undo();
  return move;
}

export function undoMove(chess, chessground) {
  chess.undo();
  chessground.set({
    fen: chess.fen(),
    turnColor: toColor(chess),
    movable: {
      color: toColor(chess),
      free: false,
      dests: toDests(chess.moves),
    },
  });
}

// move is long algebraic e2e4, e7e5, etc...
export function makeMove(move, chessjs, chessground) {
  let from, to, promotion;
  [from, to, promotion] = move.match(/.{1,2}/g);
  chessjs.move({ from: from, to: to, promotion: promotion });
  chessground.set({ fen: chessjs.fen() });
}

export function gameResult(chess) {
  let result;
  if (chess.in_checkmate()) {
    result = chess.turn() === 'w' ? 'b' : 'w';
  } if (chess.in_stalemate()) {
    result = 'stalemate';
  } if (chess.in_draw()) {
    result = 'draw';
  } if (chess.in_threefold_repetition()) {
    result = 'draw_three';
  }

  return result;
}
