import React from 'react';
import PropTypes from 'prop-types';

class GoogleAdResponsive extends React.Component {
  componentDidMount() {
    if (process.env.GOOGLE_ADS === 'false') return;

    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    if (process.env.GOOGLE_ADS === 'false') return null;

    const {
      client,
      slot,
      format,
    } = this.props;
    const style = { display: 'block' };

    return (
      <ins
        className="adsbygoogle"
        style={style}
        data-ad-client={client}
        data-ad-slot={slot}
        data-ad-format={format}
        data-full-width-responsive="true"
      />
    );
  }
}

GoogleAdResponsive.propTypes = {
  client: PropTypes.string.isRequired,
  slot: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
};

export default GoogleAdResponsive;
