import { configureStore } from '@reduxjs/toolkit'

import { castlingReducer } from '../reducers/castlingReducer';
import currentUser from '../reducers/currentUser';
import { fenReducer } from '../reducers/fenReducer';
import { moveNumReducer } from '../reducers/moveNumReducer';
import { nameInputReducer } from '../reducers/nameInputReducer';
import { fenInputReducer } from '../reducers/fenInputReducer';
import position from '../reducers/position';

export const store = configureStore({
  reducer: {
    castling: castlingReducer,
    fen: fenReducer,
    fenInput: fenInputReducer,
    moveNum: moveNumReducer,
    nameInput: nameInputReducer,
    currentUser,
    position,
  }
})
