import { EDIT_FIELD, CHANGE_FEN_VALUE } from '../actions/fenInput';

const initialState = {
  edit: false,
  newValue: null,
};

export function fenInputReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_FIELD:
      return {
        ...state,
        edit: !state.edit,
      };
    case CHANGE_FEN_VALUE:
      return {
        ...state,
        newValue: action.newValue,
      };
    default:
      return state;
  }
}
