import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateFen } from '../actions/fen';
import FeedbackModal from '../shared/FeedbackModal';

class AnalysisHeader extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.renderMoveButtons = this.renderMoveButtons.bind(this);
  }

  static renderWait() {
    return (
      <div className="alert alert-info" role="alert">
        Calculating, please wait...
      </div>
    );
  }

  handleClick(event) {
    const { dispatch, fen } = this.props;
    const numPendingMoves = Number(event.target.value)

    dispatch(updateFen(fen, numPendingMoves));
  }

  renderError() {
    const { fen } = this.props;
    return (
      <div className="alert alert-danger" role="alert">
        {fen.error}
        <FeedbackModal />
      </div>
    );
  }

  getColor(fen_id) {
    return fen_id.split(' ')[1];
  }

  renderMoveButtons() {
    const { fen } = this.props;
    const { sequence, score } = fen.analysis;

    const bestMove = this.renderFirstMoveButton(sequence[0], 1);
    const nextMoves = this.renderNextMoveButtons(sequence.slice(1, 4));

    return (
      <div className="alert alert-success" role="alert">
        {bestMove}
        &nbsp;
        Score:
        &nbsp;
        {score}
        &nbsp;
        {nextMoves.length > 0 ? 'sequence' : ''}
        &nbsp;
        {nextMoves}
        <FeedbackModal />
      </div>
    );
  }

  renderFirstMoveButton(move, moveNum) {
    const btnClass = this.getColor(this.props.fen.id) === 'w' ? 'btn-light' : 'btn-dark';

    return (
      <>
        The best move is
        &nbsp;
        <button
          type="button"
          className={`btn btn-sm ${btnClass}`}
          value={moveNum}
          onClick={this.handleClick}
        >
          {move}
        </button>
      </>
    );
  }

  renderNextMoveButtons(nextMoves) {
    let moveNum = 2;
    let btnClass = this.getColor(this.props.fen.id) === 'w' ? 'btn-dark' : 'btn-light';

    return nextMoves.map((move, _index) => {
      const button = (
        <button
          key={move}
          type="button"
          className={`btn mr-2 btn-sm ${btnClass}`}
          value={moveNum}
          onClick={this.handleClick}
        >
          {move}
        </button>
      );
      // Toggle button class and increment move number for next button
      btnClass = btnClass === 'btn-light' ? 'btn-dark' : 'btn-light';
      moveNum++;
      return button;
    });
  }

  render() {
    const { fen } = this.props;

    if (fen.status === 'loading') return AnalysisHeader.renderWait();
    if (fen.error) return this.renderError();
    if (fen.analysis.sequence) return this.renderMoveButtons();

    return (
      <div className="alert alert-primary" role="alert">
        Enter your position onto the board below, press White or Black to move.
        <strong className="text-success"> We will calculate the best move.</strong>
      </div>
    );
  }
}

AnalysisHeader.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fen: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { fen } = state;

  return { fen };
}

export default connect(mapStateToProps)(AnalysisHeader);
