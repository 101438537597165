export const EDIT_NAME = 'EDIT_NAME';
export const CHANGE_NAME_VALUE = 'CHANGE_NAME_VALUE';

export function toggleEdit() {
  return { type: EDIT_NAME };
}

export function updateNameInput(json) {
  return {
    type: CHANGE_NAME_VALUE,
    newValue: json.newValue,
  };
}
