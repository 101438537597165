import axiosCSRF from '../util/axiosCSRF';

export const REQUEST_FEN = 'REQUEST_FEN';
export const RECEIVE_FEN = 'RECEIVE_FEN';
export const RECEIVE_ERROR = 'RECEIVE_ERROR';

function requestFen(){
  return {
    type: REQUEST_FEN,
    status: 'loading',
    analysis: null,
    numPendingMoves: 0,
  }
}

function receiveFen(json, numPendingMoves) {
  return {
    type: RECEIVE_FEN,
    status: 'idle',
    id: json.id,
    analysis: json.analysis,
    numPendingMoves: numPendingMoves,
  };
}

function receiveError(json) {
  return {
    type: RECEIVE_ERROR,
    status: 'idle',
    error: json.data.errors,
  };
}

export function getFen(fen, rating) {
  return (dispatch) => axiosCSRF
    .get('/api/v1/fens.json', { params: { fen, rating } })
    .then((response) => {
      dispatch(receiveFen(response.data, 1))
    })
    .catch((error) => {
      dispatch(receiveError(error.response))
    });
}

export function createFen(dispatch, fen_string, numPendingMoves) {
  dispatch(requestFen()) // set loading status

  return (dispatch) => axiosCSRF
    .post('/api/v1/fens.json', { fen: fen_string })
    .then((response) => {
      dispatch(receiveFen(response.data, numPendingMoves))
    })
    .catch((error) => {
      dispatch(receiveError(error.response))
    });
}

export function updateFen(fen, numPendingMoves) {
  return {
    type: RECEIVE_FEN,
    status: 'idle',
    id: fen.id,
    analysis: fen.analysis,
    fen: fen,
    numPendingMoves: numPendingMoves,
  }
}

export function resetFen() {
  return {
    type: RECEIVE_FEN,
    analysis: {},
    error: null,
    pendingMoves: [],
    status: 'idle',
  }
}

export function adminGetFen(fen) {
  return (dispatch) => axiosCSRF
    .get('/admin/api/v1/fens', { params: { fen } })
    .then((response) => {
      dispatch(receiveFen(response.data, 1))
    })
}
