import { REQUEST_FEN, RECEIVE_FEN, RECEIVE_ERROR } from '../actions/fen';

const initialState = {
  analysis: {},
  error: null, // not used for now
  pendingMoves: [],
  status: 'idle', // idle, loading
}

export function fenReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_FEN:
      return {
        ...state,
        status: action.status,
        error: null,
      };
    case RECEIVE_FEN:
      return {
        ...state,
        status: action.status,
        id: action.id,
        analysis: action.analysis,
        pendingMoves: (action.numPendingMoves > 0) ? action.analysis.sequence.slice(0, action.numPendingMoves) : [],
      };
    case RECEIVE_ERROR:
      return {
        ...state,
        status: action.status,
        error: action.error,
      };
    default:
      return state;
  }
}
