export const EDIT_FIELD = 'EDIT_FIELD';
export const CHANGE_FEN_VALUE = 'CHANGE_FEN_VALUE';

export function toggleEdit() {
  return { type: EDIT_FIELD };
}

export function updateFenInput(json) {
  return {
    type: CHANGE_FEN_VALUE,
    newValue: json.newValue,
  };
}
