import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { resetFen } from '../actions/fen';
import { getPosition, createOrUpdatePosition } from '../actions/position';
import { updateCastling } from '../actions/castling';
import { updateMoveNum } from '../actions/moveNum';
import AnalysisHeader from './AnalysisHeader';
import AnalysisBoard from './AnalysisBoard';
import AnalysisControls from './AnalysisControls';
import AnalysisCastlingCheckbox from './AnalysisCastlingCheckbox';
import AnalysisSettings from './AnalysisSettings';
import GoogleAdResponsive from '../adsense/GoogleAdResponsive';

class Analysis extends React.Component {
  constructor(props) {
    super(props);
    this.cgEventChange = this.cgEventChange.bind(this);
    this.updateOrientation = this.updateOrientation.bind(this);
    this.resetBoard = this.resetBoard.bind(this);
    this.clearBoard = this.clearBoard.bind(this);
  }

  // supports edit and new
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getPosition())
      .then((response) => {
        const { position, moveNum, orientation } = response;
        chessground.set({ fen: position.moves[moveNum], orientation });
        dispatch(updateCastling(chessground));
        dispatch(updateMoveNum(moveNum));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  cgEventChange() {
    const { dispatch, position, castling } = this.props;

    const json = { moves: position.moves.concat([chessground.getFen()]) }
    dispatch(createOrUpdatePosition(position, json))
    dispatch(resetFen());
    dispatch(updateCastling(chessground));
  }

  updateOrientation() {
    chessground.toggleOrientation();

    const { dispatch, position } = this.props;

    const json = { orientation: chessground.state.orientation[0] }
    dispatch(createOrUpdatePosition(position, json))
  }

  resetBoard() {
    chessground.set({ fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR' });
    this.cgEventChange();
  }

  clearBoard() {
    chessground.set({ fen: '8/8/8/8/8/8/8/8' });
    this.cgEventChange();
  }

  render() {
    return (
      <div>
        <AnalysisHeader />
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12 mr-3">
            <AnalysisBoard cgEventChange={this.cgEventChange} />
          </div>
          <div className="col-lg-4 col-md-9 col-sm-9">
            <AnalysisControls
              flipBtn={(
                <button type="button" className="btn btn-outline-info me-1" onClick={this.updateOrientation}>
                  Flip
                </button>
              )}
              resetBtn={(
                <button type="button" className="btn btn-outline-info me-1" onClick={this.resetBoard}>
                  Reset
                </button>
              )}
              clearBtn={(
                <button type="button" className="btn btn-outline-warning me-1" onClick={this.clearBoard}>
                  Clear
                </button>
              )}
              Settings={(
                <AnalysisSettings
                  cgEventChange={this.cgEventChange}
                />
              )}
            >
              <p className="text-black-50 bg-white">
                White Castling&nbsp;
                <AnalysisCastlingCheckbox value="K" label="kingside" />
                <AnalysisCastlingCheckbox value="Q" label="queenside" />
              </p>
              <p className="text-white-50 bg-dark">
                Black Castling&nbsp;
                <AnalysisCastlingCheckbox value="k" label="kingside" />
                <AnalysisCastlingCheckbox value="q" label="queenside" />
              </p>
            </AnalysisControls>
          </div>
        </div>
        <br />
        <br />
        <GoogleAdResponsive key={Math.random()} client="ca-pub-6168751190311953" slot="3719458166" format="auto" />
      </div>
    );
  }
}

Analysis.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    move_automatically: PropTypes.bool,
  }),
  castling: PropTypes.shape({
    K: PropTypes.bool,
    Q: PropTypes.bool,
    k: PropTypes.bool,
    q: PropTypes.bool,
  }),
};

function mapStateToProps(state) {
  const { analysis, castling, currentUser, position } = state;

  return { analysis, castling, currentUser, position };
}

export default connect(mapStateToProps)(Analysis);
