import React from 'react';
import axiosCSRF from '../util/axiosCSRF';
import FlagIcon from '../icons/flagIcon';

class FeedbackModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { info: '', message: '' };
    this.closeBtn = React.createRef();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    this.closeBtn.current.click();
    this.setState({ submitted: true });
    const { info, message } = this.state;
    const url = '/api/v1' + window.location.pathname;

    axiosCSRF
      .post(
        '/api/v1/feedbacks',
        {
          feedback: {
            url, info, message, fen: chessground.getFen(),
          },
        },
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  }

  render() {
    const { submitted } = this.state;

    if (submitted) {
      return (
        <span className="float-right">
          Thanks for your feedback.
        </span>
      );
    }

    return (
      <>
        <button type="button" id="reportProblem" className="float-right btn btn-link" data-bs-toggle="modal" data-bs-target="#feedbackModal" title="Report a problem">
          <FlagIcon />
        </button>
        <div className="alert-secondary">
          <div id="feedbackModal" className="modal fade" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4>Report a problem</h4>
                </div>
                <form onSubmit={this.handleSubmit} className="form form-horizontal">
                  <div className="modal-body">
                    <div className="form-group">
                      <label htmlFor="message">
                        Message
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Tell us about your problem"
                        onChange={this.handleInputChange}
                        name="message"
                        rows="5"
                      />
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button ref={this.closeBtn} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FeedbackModal;
