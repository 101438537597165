export function getCastlingString(castling) {
  let castlingString = ""

  for (let [key, value] of Object.entries(castling)) {
    if(value === true) castlingString += key;
  }

  return castlingString || "-"
}

export function isCastlingAvailable(chessground, value) {
  switch (value) {
    case 'K':
      return (
        chessground.state.pieces.get('e1') !== undefined
        && chessground.state.pieces.get('e1').role === 'king'
        && chessground.state.pieces.get('e1').color === 'white'
        && chessground.state.pieces.get('h1') !== undefined
        && chessground.state.pieces.get('h1').role === 'rook'
        && chessground.state.pieces.get('h1').color === 'white'
      );
    case 'Q':
      return (
        chessground.state.pieces.get('e1') !== undefined
        && chessground.state.pieces.get('e1').role === 'king'
        && chessground.state.pieces.get('e1').color === 'white'
        && chessground.state.pieces.get('a1') !== undefined
        && chessground.state.pieces.get('a1').role === 'rook'
        && chessground.state.pieces.get('a1').color === 'white'
      );
    case 'k':
      return (
        chessground.state.pieces.get('e8') !== undefined
        && chessground.state.pieces.get('e8').role === 'king'
        && chessground.state.pieces.get('e8').color === 'black'
        && chessground.state.pieces.get('h8') !== undefined
        && chessground.state.pieces.get('h8').role === 'rook'
        && chessground.state.pieces.get('h8').color === 'black'
      );
    case 'q':
      return (
        chessground.state.pieces.get('e8') !== undefined
        && chessground.state.pieces.get('e8').role === 'king'
        && chessground.state.pieces.get('e8').color === 'black'
        && chessground.state.pieces.get('a8') !== undefined
        && chessground.state.pieces.get('a8').role === 'rook'
        && chessground.state.pieces.get('a8').color === 'black'
      );
    default:
      return false;
  }
}
