import { RECEIVE_CASTLING } from '../actions/castling';

const initialState = {
  K: true,
  Q: true,
  k: true,
  q: true,
}

export function castlingReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_CASTLING:
      return action.castling;
    default:
      return state;
  }
}
