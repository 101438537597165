import { RECEIVE_MOVENUM } from '../actions/moveNum';

const initialState = null;

export function moveNumReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_MOVENUM:
      return action.moveNum;
    default:
      return state;
  }
}
