import axios from 'axios';

export const token = document.querySelector('[name="csrf-token"]') || { content: 'no-csrf-token' };
const axiosCSRF = axios.create({
  headers: {
    common: {
      'X-CSRF-Token': token.content,
    },
  },
});

export default axiosCSRF;
