import axiosCSRF from '../util/axiosCSRF';

export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';

export function receiveCurrentUser(json) {
  return {
    type: RECEIVE_CURRENT_USER,
    currentUser: json.current_user,
  };
}

export function getCurrentUser() {
  return (dispatch) => axiosCSRF
    .get('/api/v1/users/')
    .then((response) => dispatch(receiveCurrentUser(response.data)));
}

export function updateCurrentUser(userId, userParams) {
  return (dispatch) => axiosCSRF
    .put(
      '/api/v1/users/' + userId + '.json',
      { user: userParams },
    )
    .then((response) => dispatch(receiveCurrentUser(response.data)));
}
