import { RECEIVE_POSITION } from '../actions/position';

function position(state = {}, action) {
  console.log(action.type)
  switch (action.type) {
    case RECEIVE_POSITION:
      return action.position;
    default:
      return state;
  }
}

export default position;
