import React from 'react';
import { createRoot } from 'react-dom/client'

import { Provider } from 'react-redux';
import { store } from './store'
import { getCurrentUser } from '../actions/currentUser';
import Analysis from './Analysis';

const root = createRoot(document.getElementById('js-root'))
store.dispatch(getCurrentUser());

root.render(
  <Provider store={store}>
    <Analysis />
  </Provider>
)
